import './Founders.svelte.css';
/* src/components/Founders.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import "./lib/theme.css";
import Text from "./lib/Text.svelte.js";
import { TextSize } from "./lib/types.js";
import Carat from "./Carat.svelte.js";

function create_default_slot_3(ctx) {
	let t;

	return {
		c() {
			t = text("SNØCAP is a venture capital firm that believes in the power of scientific innovation to solve the climate crisis.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (12:1) <Text data-aos="fade-in" size={TextSize.Aside} class="box aside">
function create_default_slot_2(ctx) {
	let t0;
	let a0;
	let t2;
	let a1;
	let t4;

	return {
		c() {
			t0 = text("We are ");
			a0 = element("a");
			a0.textContent = "three engineers";
			t2 = text(" who have spent our careers assembling one of ");
			a1 = element("a");
			a1.textContent = "the best ecosystems for deep tech entrepreneurs in the world";
			t4 = text(". \n\t\tWe work best with early stage founders, and we invest in the sort of business that:");
			attr(a0, "href", "#general-partners");
			attr(a1, "href", "https://www.sweetfarm.org/technology");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, a0, anchor);
			insert(target, t2, anchor);
			insert(target, a1, anchor);
			insert(target, t4, anchor);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(a0);
			if (detaching) detach(t2);
			if (detaching) detach(a1);
			if (detaching) detach(t4);
		}
	};
}

// (16:1) <Text size={TextSize.Paragraph} data-aos="fade-in" class="box no-pad-right">
function create_default_slot_1(ctx) {
	let ul;

	return {
		c() {
			ul = element("ul");

			ul.innerHTML = `<li class="svelte-1e9tr5i">📈 Create product(s) that are <strong>immediately cheaper, or dramatically more effective</strong>, than the products they are replacing.</li> 
			<li class="svelte-1e9tr5i">📉 Lower the cost of doing business for their customers, which are <strong>typically other businesses</strong>.</li> 
			<li class="svelte-1e9tr5i">🌱 <strong>Reduce the immediate and downstream pollution</strong> their customers emit as a result of adopting their products.</li> 
			<li class="svelte-1e9tr5i">🧠 Are in the process of <strong>commercializing intellectual property</strong> (IP) that they own or license.</li> 
			<li class="svelte-1e9tr5i">📚 Developed their IP through years of <strong>academic research or indigenous learnings</strong>.</li> 
			<li class="svelte-1e9tr5i">📦 Have <strong>exhibited corporate interest</strong>, ranging from written support through to direct sponsorship and purchase orders.</li> 
			<li class="svelte-1e9tr5i">🧪 Will benefit from the <strong>help of engineers and scientists</strong> to predict the market value of their technology.</li> 
			<li class="svelte-1e9tr5i">🌍 Could immediately <strong>leverage an ecosystem</strong> of academic partners, cheap field trials, and/or grant application support.</li> 
			<li class="svelte-1e9tr5i">💰 Are <strong>raising their first round of capital</strong>, outside of grants and other non-dilutive capital.</li>`;

			attr(ul, "class", "svelte-1e9tr5i");
		},
		m(target, anchor) {
			insert(target, ul, anchor);
		},
		d(detaching) {
			if (detaching) detach(ul);
		}
	};
}

// (29:1) <Text size={TextSize.Aside} class="box">
function create_default_slot(ctx) {
	let t0;
	let a;
	let t2;

	return {
		c() {
			t0 = text("If this sounds like your startup, or one you know, you can ");
			a = element("a");
			a.textContent = "submit the company for consideration here";
			t2 = text(". \n\t\tOur team reviews new opportunities every week!");
			attr(a, "href", "https://snocap.vc/apply");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, a, anchor);
			insert(target, t2, anchor);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(a);
			if (detaching) detach(t2);
		}
	};
}

function create_fragment(ctx) {
	let section;
	let img;
	let img_src_value;
	let t0;
	let text0;
	let t1;
	let text1;
	let t2;
	let text2;
	let t3;
	let text3;
	let t4;
	let carat;
	let current;

	text0 = new Text({
			props: {
				"data-aos": "fade-in",
				size: TextSize.Header,
				class: "box",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	text1 = new Text({
			props: {
				"data-aos": "fade-in",
				size: TextSize.Aside,
				class: "box aside",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	text2 = new Text({
			props: {
				size: TextSize.Paragraph,
				"data-aos": "fade-in",
				class: "box no-pad-right",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	text3 = new Text({
			props: {
				size: TextSize.Aside,
				class: "box",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	carat = new Carat({ props: { fragment: "general-partners" } });

	return {
		c() {
			section = element("section");
			img = element("img");
			t0 = space();
			create_component(text0.$$.fragment);
			t1 = space();
			create_component(text1.$$.fragment);
			t2 = space();
			create_component(text2.$$.fragment);
			t3 = space();
			create_component(text3.$$.fragment);
			t4 = space();
			create_component(carat.$$.fragment);
			attr(img, "data-aos", "fade-in");
			attr(img, "class", "banner svelte-1e9tr5i");
			if (img.src !== (img_src_value = "/img/social-banner-nologo.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "SNØCAP Banner");
			attr(section, "id", "founders");
			attr(section, "data-header-content", "Apply For Funding");
			attr(section, "class", "svelte-1e9tr5i");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, img);
			append(section, t0);
			mount_component(text0, section, null);
			append(section, t1);
			mount_component(text1, section, null);
			append(section, t2);
			mount_component(text2, section, null);
			append(section, t3);
			mount_component(text3, section, null);
			append(section, t4);
			mount_component(carat, section, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const text0_changes = {};

			if (dirty & /*$$scope*/ 1) {
				text0_changes.$$scope = { dirty, ctx };
			}

			text0.$set(text0_changes);
			const text1_changes = {};

			if (dirty & /*$$scope*/ 1) {
				text1_changes.$$scope = { dirty, ctx };
			}

			text1.$set(text1_changes);
			const text2_changes = {};

			if (dirty & /*$$scope*/ 1) {
				text2_changes.$$scope = { dirty, ctx };
			}

			text2.$set(text2_changes);
			const text3_changes = {};

			if (dirty & /*$$scope*/ 1) {
				text3_changes.$$scope = { dirty, ctx };
			}

			text3.$set(text3_changes);
		},
		i(local) {
			if (current) return;
			transition_in(text0.$$.fragment, local);
			transition_in(text1.$$.fragment, local);
			transition_in(text2.$$.fragment, local);
			transition_in(text3.$$.fragment, local);
			transition_in(carat.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(text0.$$.fragment, local);
			transition_out(text1.$$.fragment, local);
			transition_out(text2.$$.fragment, local);
			transition_out(text3.$$.fragment, local);
			transition_out(carat.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(text0);
			destroy_component(text1);
			destroy_component(text2);
			destroy_component(text3);
			destroy_component(carat);
		}
	};
}

class Founders extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Founders;