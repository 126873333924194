import './People.svelte.css';
/* src/components/People.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import "./lib/theme.css";
import Person from "./Person.svelte.js";

function create_default_slot_6(ctx) {
	let t;

	return {
		c() {
			t = text("Mechanical engineer and sustainability scientist with a decade of experience building, investing in, and advocating for frontier climate tech. She invested in early stage, mission driven companies with Better Ventures and built out Republic’s business lines as VP, democratizing access to capital. Shrina ran for United States Congress championing strong climate policy.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (12:1) <Person   name="Nate Salpeter, GP"   linkedinURI="https://www.linkedin.com/in/nate-salpeter-77145712/"   headshotURI="/headshots/ns.jpeg"   reversed>
function create_default_slot_5(ctx) {
	let t;

	return {
		c() {
			t = text("Ph.D. Mechanical Engineer with 13 years experience in the climate tech space, including 5 years at Bill Gates’ TerraPower. He is the co-founder of Sweet Farm, a New York based non-profit incubating food, agriculture, and climate tech startups. He invests and personally advises in emerging sectors, including being the first investor in and an advisor to TurtleTree Labs");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (19:1) <Person   name="Jonathan Azoff, GP"   linkedinURI="https://www.linkedin.com/in/jazoff/"   headshotURI="/headshots/ja.jpeg">
function create_default_slot_4(ctx) {
	let t;

	return {
		c() {
			t = text("Software engineering leader with twenty years of experience across a wide array of sectors (social, gaming, real estate, fitness, logistics, fintech) and two notable exits in the past ten years (Zillow, Disney). He’s the inventor of Goat-2-Meeting (2M+ ARR), the primary architect behind Omni (25M+ XRP raise). He managed several teams at Carta (7B valuation), where he led tool development for the VC back office.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (27:1) <Person   name="Abigail Albright"   linkedinURI="https://www.linkedin.com/in/abigailalbright/"   headshotURI="/headshots/aa.jpeg"   reversed>
function create_default_slot_3(ctx) {
	let t;

	return {
		c() {
			t = text("Abby is a seasoned investor and product leader with over a decade of experience. She has expertise in leading product management, business development, and go-to-market strategies for early-stage startups and global franchises alike. As an investor, Abby has supported seed-series B focused venture funds with $2B+in AUM, backed 35+ startups as a generalist angel investor, and co-founded an early-stage spatial computing fund. Startups she has supported have raised over $500M in subsequent funding. Abby holds an MBA from The Wharton School at UPenn and a BA in Neuroscience from UC Berkeley.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (34:1) <Person   name="Kate Kingen"   linkedinURI="https://www.linkedin.com/in/katekingen/"   headshotURI="/headshots/kk.jpeg">
function create_default_slot_2(ctx) {
	let t;

	return {
		c() {
			t = text("Kate is a category expert, seasoned senior leader, and finance executive with over a decade of experience. She has led growth companies in the food and DTC consumer industries, managed major governmental organizations, and advised on transactions totaling over $21bn in deal value as an investment banker.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (40:1) <Person   name="Kelci Zile"   linkedinURI="https://www.linkedin.com/in/kzile/"   headshotURI="/headshots/kz.jpeg"   reversed>
function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text("Kelci Zile is the Chief Development Officer of Sound Future (a nonprofit that leverages the power of live events as a testbed climate solutions), sits on the University of Washington Buerk Center for Entrepreneurship board, is the Director of Venture Community for EnVest, and previously led sustainability investments for Madrona Venture Labs. Before investing, she led sustainability for AWS Marketplace and served on the Amazon Sustainability Ambassadors Global Board where she co-led an Amazon-wide internal education series. Her expertise includes the circular economy, supply chain emissions reduction, net-zero planning, and nature-based solutions.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (47:1) <Person   name="HonMun Yip"   linkedinURI="https://www.linkedin.com/in/yiphonmun/"   headshotURI="/headshots/hy.jpeg">
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Hon Mun has over thirty years of investment, operations, and board level experience with PE funds, family offices and start-ups. For more than a decade and a half, he was based in Hong Kong, Bangkok, Seoul and China performing Post M&A integration, turning-around and growing businesses for Private Equity Funds and family-owned Asia businesses. He ventured into ESG and Sustainability businesses when he was the CEO of a Singapore-China government Joint Venture pioneering in sustainable farming and food security in North East China. He served as the EVP for Turtletree Lab, COO for an Impact Philanthropy fund and an active investor in the alternative protein space.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let section0;
	let person0;
	let t0;
	let person1;
	let t1;
	let person2;
	let t2;
	let section1;
	let person3;
	let t3;
	let person4;
	let t4;
	let person5;
	let t5;
	let person6;
	let current;

	person0 = new Person({
			props: {
				name: "Shrina Kurani, MP",
				linkedinURI: "https://www.linkedin.com/in/shrina-kurani-94a88b56/",
				headshotURI: "/headshots/sk.jpeg",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	person1 = new Person({
			props: {
				name: "Nate Salpeter, GP",
				linkedinURI: "https://www.linkedin.com/in/nate-salpeter-77145712/",
				headshotURI: "/headshots/ns.jpeg",
				reversed: true,
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	person2 = new Person({
			props: {
				name: "Jonathan Azoff, GP",
				linkedinURI: "https://www.linkedin.com/in/jazoff/",
				headshotURI: "/headshots/ja.jpeg",
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	person3 = new Person({
			props: {
				name: "Abigail Albright",
				linkedinURI: "https://www.linkedin.com/in/abigailalbright/",
				headshotURI: "/headshots/aa.jpeg",
				reversed: true,
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	person4 = new Person({
			props: {
				name: "Kate Kingen",
				linkedinURI: "https://www.linkedin.com/in/katekingen/",
				headshotURI: "/headshots/kk.jpeg",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	person5 = new Person({
			props: {
				name: "Kelci Zile",
				linkedinURI: "https://www.linkedin.com/in/kzile/",
				headshotURI: "/headshots/kz.jpeg",
				reversed: true,
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	person6 = new Person({
			props: {
				name: "HonMun Yip",
				linkedinURI: "https://www.linkedin.com/in/yiphonmun/",
				headshotURI: "/headshots/hy.jpeg",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			section0 = element("section");
			create_component(person0.$$.fragment);
			t0 = space();
			create_component(person1.$$.fragment);
			t1 = space();
			create_component(person2.$$.fragment);
			t2 = space();
			section1 = element("section");
			create_component(person3.$$.fragment);
			t3 = space();
			create_component(person4.$$.fragment);
			t4 = space();
			create_component(person5.$$.fragment);
			t5 = space();
			create_component(person6.$$.fragment);
			attr(section0, "id", "general-partners");
			attr(section0, "data-header-content", "General Partners");
			attr(section0, "class", "svelte-11w0v3w");
			attr(section1, "id", "venture-partners");
			attr(section1, "data-header-content", "Venture Partners");
			attr(section1, "class", "svelte-11w0v3w");
		},
		m(target, anchor) {
			insert(target, section0, anchor);
			mount_component(person0, section0, null);
			append(section0, t0);
			mount_component(person1, section0, null);
			append(section0, t1);
			mount_component(person2, section0, null);
			insert(target, t2, anchor);
			insert(target, section1, anchor);
			mount_component(person3, section1, null);
			append(section1, t3);
			mount_component(person4, section1, null);
			append(section1, t4);
			mount_component(person5, section1, null);
			append(section1, t5);
			mount_component(person6, section1, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const person0_changes = {};

			if (dirty & /*$$scope*/ 1) {
				person0_changes.$$scope = { dirty, ctx };
			}

			person0.$set(person0_changes);
			const person1_changes = {};

			if (dirty & /*$$scope*/ 1) {
				person1_changes.$$scope = { dirty, ctx };
			}

			person1.$set(person1_changes);
			const person2_changes = {};

			if (dirty & /*$$scope*/ 1) {
				person2_changes.$$scope = { dirty, ctx };
			}

			person2.$set(person2_changes);
			const person3_changes = {};

			if (dirty & /*$$scope*/ 1) {
				person3_changes.$$scope = { dirty, ctx };
			}

			person3.$set(person3_changes);
			const person4_changes = {};

			if (dirty & /*$$scope*/ 1) {
				person4_changes.$$scope = { dirty, ctx };
			}

			person4.$set(person4_changes);
			const person5_changes = {};

			if (dirty & /*$$scope*/ 1) {
				person5_changes.$$scope = { dirty, ctx };
			}

			person5.$set(person5_changes);
			const person6_changes = {};

			if (dirty & /*$$scope*/ 1) {
				person6_changes.$$scope = { dirty, ctx };
			}

			person6.$set(person6_changes);
		},
		i(local) {
			if (current) return;
			transition_in(person0.$$.fragment, local);
			transition_in(person1.$$.fragment, local);
			transition_in(person2.$$.fragment, local);
			transition_in(person3.$$.fragment, local);
			transition_in(person4.$$.fragment, local);
			transition_in(person5.$$.fragment, local);
			transition_in(person6.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(person0.$$.fragment, local);
			transition_out(person1.$$.fragment, local);
			transition_out(person2.$$.fragment, local);
			transition_out(person3.$$.fragment, local);
			transition_out(person4.$$.fragment, local);
			transition_out(person5.$$.fragment, local);
			transition_out(person6.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section0);
			destroy_component(person0);
			destroy_component(person1);
			destroy_component(person2);
			if (detaching) detach(t2);
			if (detaching) detach(section1);
			destroy_component(person3);
			destroy_component(person4);
			destroy_component(person5);
			destroy_component(person6);
		}
	};
}

class People extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default People;